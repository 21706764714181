import { combineEpics, ofType } from 'redux-observable';

import { of, throwError, merge } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { loadSurveyScreen } from '../SurveyScreen/SurveyScreen.actions';
import { mapGetOffer } from '../SummaryScreen/SummaryScreen.actions';

import {
    PACKAGE_SCREEN_VALIDATE,
    PACKAGE_SCREEN_VALIDATE_SUCCESS,
    PACKAGE_SCREEN_VALIDATE_FAILURE,
} from '../types';

import WordingConstant from '../../utils/wording';
import { log } from '../../utils/logger';
import { getPointOfDeliveryLogger } from '../../utils/helpers';
import { changeCodeMode } from '../CodeScreen/CodeScreen.actions';
import { CodeScreenMode } from '../../constants/enums';

const WordingCodeScreenError = WordingConstant.CodeScreen;
const Wording = WordingConstant.Common;

const validatePackageScreen = payload => ({
    type: PACKAGE_SCREEN_VALIDATE,
    payload,
});

const validatePackageScreenSuccess = payload => ({
    type: PACKAGE_SCREEN_VALIDATE_SUCCESS,
    payload,
});

const validatePackageScreenFailure = payload => ({
    type: PACKAGE_SCREEN_VALIDATE_FAILURE,
    payload,
});

const preparePayloadGetProduct = ({
                                      userType,
                                      energyTypes,
                                      contracts,
                                      ...payload
                                  }) => {
    const modifiedContracts = contracts.map(contract => {
        const { energy } = contract;
        const billing = {};

        if (payload[energy].cyclicBill) {
            billing.billingModeCode = Wording.billingModeCode.cyclic;
        } else if (payload[energy].billingModeCode !== undefined) {
            billing.billingModeCode = payload[energy].billingModeCode;
        } else {
            billing.billingModeCode = Wording.billingModeCode.schedule;
        }
        billing.installmentFrequency = payload[energy].installmentFrequency;
        return {
            ...contract,
            ...billing,
        };
    });
    return {
        contextOfUse: 'MARKET',
        customerType: userType,
        contracts: modifiedContracts,
    };
};

const mapGetProducts = (action, { apiRequest }) => {
    const payload = preparePayloadGetProduct(action.payload);

    return apiRequest({
        path: '/getProducts',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.productsList) {
                const chosenProducts = response.productsList.filter(
                    product => product.default,
                );
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        chosenProducts,
                        products: response.productsList,
                    },
                };

                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getProducts',
                    statusAction: 'SUCCESS',
                });

                return mapGetOffer(
                    modifiedAction,
                    { apiRequest },
                    validatePackageScreenSuccess,
                    validatePackageScreenFailure,
                );
            }
            const message = response.message || response.errorMessage;

            log({
                ...action.payload.logger,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getProducts',
                statusAction: 'FAILURE',
            });
            return throwError({ message });
        }),
        catchError(error =>
            of(validatePackageScreenFailure({ error: error.message })),
        ),
    );
};

const validatePackageScreenEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(PACKAGE_SCREEN_VALIDATE),
        mergeMap(action => mapGetProducts(action, dependency)),
    );

const mapGetPackages = (
    action,
    { apiRequest },
    preparePayload,
    success,
    failure,
) => {
    const payload = preparePayload(action.payload);
    const option = {
        path: '/getPackages',
        method: 'post',
        body: payload,
    };
    // if (Config.FunctionName.getPackages) {
    //     option.lambda = {
    //         functionName: Config.FunctionName.getPackages,
    //     };
    // }

    return apiRequest(option).pipe(
        mergeMap(response => {
            const message = response.message || response.errorMessage;
            if (message) {
                const {
                    energyList,
                    customerIdentificationKey,
                } = getPointOfDeliveryLogger(payload.pointOfDeliveryList);

                log({
                    ...action.payload.logger,
                    energyList,
                    customerIdentificationKey,
                    customerType: payload.customerType,
                    returnCode: response.code,
                    logMessage: response.message,
                    serviceCalled: '/getPackages',
                    statusAction: 'FAILURE',
                });
                return throwError({ message });
            }
            const packages = response.packagesList;
            const isCyclicalOfferExist =
                response.autorizedBillingModes &&
                response.autorizedBillingModes.length > 1;

            if (action.payload.isFinal) {
                const { contracts } = action.payload;
                const modifiedContracts = response.contracts.map(contract => {
                    const currentContract = contracts.find(
                        c => c.energy === contract.energy,
                    );

                    const notNullEstimate = contract.estimates
                        ? contract.estimates.find(es => es.quantity > 0)
                        : false;

                    const estimates = notNullEstimate
                        ? contract.estimates
                        : currentContract.estimates;
                    return {
                        ...contract,
                        estimates,
                    };
                });
                const modifiedAction = {
                    ...action,
                    payload: {
                        ...action.payload,
                        contracts: modifiedContracts,
                    },
                };

                log({
                    ...action.payload.logger,
                    customerType: payload.customerType,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getPackages',
                    statusAction: 'SUCCESS',
                });

                return merge(
                    mapGetProducts(modifiedAction, { apiRequest }),
                    of(
                        success({
                            ...action.payload,
                            packages,
                            prepaymentFrequencies:
                            response.prepaymentFrequencies,
                            autorizedBillingModes:
                            response.autorizedBillingModes,
                            contracts: response.contracts,
                        }),
                    ),
                );
            }

            if (payload.pointOfDeliveryList) {
                const {
                    energyList,
                    customerIdentificationKey,
                } = getPointOfDeliveryLogger(payload.pointOfDeliveryList);

                log({
                    ...action.payload.logger,
                    energyList,
                    customerIdentificationKey,
                    customerType: payload.customerType,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getPackages',
                    statusAction: 'SUCCESS',
                });
            } else {
                const sendEnergyList = () => {
                    if (payload.survey) {
                        return payload.survey.energyList.length === 2
                            ? 'EL/NG'
                            : payload.survey.energyList[0];
                    }
                    return null;
                };
                log({
                    ...action.payload.logger,
                    customerType: payload.customerType,
                    energyList: sendEnergyList(),
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getPackages',
                    statusAction: 'SUCCESS',
                });
            }

            if (packages) {
                const { energyTypes } = action.payload;
                const myFilter = function(e) {
                    return this.indexOf(e) < 0;
                };
                const array = energyTypes.filter(
                    myFilter,
                    packages.map(p => p.energy),
                );
                if (action.payload.isAlreadyClient === false) {
                    if (response.contracts &&
                        (!response.contracts.every(contract => contract.deliveryPoint.address.postalCode === action.payload.postalCodeCustomer)
                        )) {
                        const {
                            energyList,
                            customerIdentificationKey,
                        } = getPointOfDeliveryLogger(payload.pointOfDeliveryList);

                        log({
                            ...action.payload.logger,
                            energyList,
                            customerIdentificationKey,
                            customerType: payload.customerType,
                            returnCode: response.code,
                            logMessage: response.message,
                            serviceCalled: '/getPackages',
                            statusAction: 'FAILURE',
                        });
                        return throwError({ message: WordingCodeScreenError.inputError.errorNotSamePostalCode });

                    }
                }
                let condition = false;
                if (
                    !(
                        action.payload.missingEnergy !== undefined &&
                        action.payload.missingEnergy[0]
                    )
                ) {
                    condition = packages.length === 0 || array.length > 0;
                }

                if (condition && !action.payload.noModeChange) {
                    return of(
                        changeCodeMode({
                            mode: CodeScreenMode.SIMULATION,
                        }),
                        loadSurveyScreen({
                            ...action.payload,
                            packages,
                            contracts: response.contracts,
                            missingEnergy: array,
                        }),
                    );
                }
                if (!action.payload.loadEstimate) {
                    return of(
                        changeCodeMode({
                            mode: isCyclicalOfferExist
                                ? CodeScreenMode.OFFERS
                                : CodeScreenMode.END,
                        }),
                        success({
                            ...action.payload,
                            packages,
                            prepaymentFrequencies:
                            response.prepaymentFrequencies,
                            autorizedBillingModes:
                            response.autorizedBillingModes,
                            contracts: response.contracts,
                        }),
                    );
                }
                return of(
                    success({
                        ...action.payload,
                        packages,
                        prepaymentFrequencies: response.prepaymentFrequencies,
                        autorizedBillingModes: response.autorizedBillingModes,
                        contracts: response.contracts,
                    }),
                );
            }
            return of(
                changeCodeMode({
                    mode: action.payload.noModeChange
                        ? CodeScreenMode.END
                        : CodeScreenMode.SIMULATION,
                }),
                loadSurveyScreen({
                    ...action.payload,
                    packages: [],
                    contracts: response.contracts,
                }),
            );
        }),
        catchError(error =>
            of(
                failure({
                    error: error.message,
                }),
            ),
        ),
    );
};

const packageScreenEpic = combineEpics(validatePackageScreenEpic);

export {
    validatePackageScreen,
    validatePackageScreenSuccess,
    validatePackageScreenFailure,
    packageScreenEpic,
    mapGetPackages,
};
