import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import moment from 'moment';

import {
    PREVIOUS_SCREEN_LOAD,
    NEXT_SCREEN_LOAD,
    MAIN_SCREEN_SAVE_ORDER,
    MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    MAIN_SCREEN_SAVE_ORDER_FAILURE,
    MAIN_SCREEN_GET_ORDER,
    MAIN_SCREEN_GET_ORDER_SUCCESS,
    MAIN_SCREEN_GET_ORDER_FAILURE,
    MAIN_SCREEN_GET_OFFER_FAILURE,
    MAIN_SCREEN_GET_PACKAGE_FAILURE,
    MAIN_SCREEN_GET_PRODUCT_FAILURE,
    MAIN_SCREEN_GET_FORM,
    CLOSE_SAVE_DRAFT_MODAL,
} from '../types';

import {
    dateDiffDays,
    removeFirstPaymentCBFromContract,
} from '../../utils/helpers';
import { prepareOrder } from '../FinalSummaryScreen/FinalSummaryScreen.actions';
import { log } from '../../utils/logger';
import { mapGetCalendarAndAppointment } from '../DataScreen/DataScreen.actions';

export const goPreviousScreen = payload => ({
    type: PREVIOUS_SCREEN_LOAD,
    payload,
});

export const goNextScreen = payload => ({
    type: NEXT_SCREEN_LOAD,
    payload,
});

export const saveOrder = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER,
    payload,
});

export const saveOrderSuccess = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER_SUCCESS,
    payload,
});

export const saveOrderFailure = payload => ({
    type: MAIN_SCREEN_SAVE_ORDER_FAILURE,
    payload,
});

export const getOrder = payload => ({
    type: MAIN_SCREEN_GET_ORDER,
    payload,
});

export const getOrderSuccess = payload => ({
    type: MAIN_SCREEN_GET_ORDER_SUCCESS,
    payload,
});

export const getOrderFailure = payload => ({
    type: MAIN_SCREEN_GET_ORDER_FAILURE,
    payload,
});

export const getOfferFailure = payload => ({
    type: MAIN_SCREEN_GET_OFFER_FAILURE,
    payload,
});

export const getPackageFailure = payload => ({
    type: MAIN_SCREEN_GET_PACKAGE_FAILURE,
    payload,
});

export const getProductFailure = payload => ({
    type: MAIN_SCREEN_GET_PRODUCT_FAILURE,
    payload,
});

export const getForm = payload => ({
    type: MAIN_SCREEN_GET_FORM,
    payload,
});

export const closeSaveDraftModal = () => ({
    type: CLOSE_SAVE_DRAFT_MODAL,
});

const preparePayload = payload => {
    const order = prepareOrder(payload);

    const agent = localStorage.getItem('agent');

    if (agent) {
        order.createdBy = agent;
    }

    return {
        savingMode: 'SEND_EMAIL_LINK',
        order,
    };
};

const mapSaveOrder = (action, { apiRequest }) => {
    const payload = preparePayload(action.payload);
    return apiRequest({
        path: '/saveOrder',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.orderNumber) {
                log({
                    ...action.payload.logger,
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/saveOrder',
                    statusAction: 'SUCCESS',
                });
                return of(
                    saveOrderSuccess({
                        ...action.payload,
                        orderNumber: response.orderNumber,
                    })
                );
            }
            const message = response.message || response.errorMessage;

            log({
                ...action.payload.logger,
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/saveOrder',
                statusAction: 'FAILURE',
            });
            return throwError({ message });
        }),
        catchError(error => {
            log({
                ...action.payload.logger,
                returnCode: error.code,
                logMessage: error.message,
                serviceCalled: '/saveOrder',
                statusAction: 'FAILURE',
            });
            return of(
                saveOrderFailure({
                    error: error.message,
                })
            );
        })
    );
};

const saveOrderEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(MAIN_SCREEN_SAVE_ORDER),
        mergeMap(action => mapSaveOrder(action, dependency))
    );

const preparePayloadGetOrder = ({ id }) => ({
    orderNumber: id,
});

const mapGetOrder = (action, { apiRequest }) => {
    const payload = preparePayloadGetOrder(action.payload);
    const option = {
        path: '/getOrder',
        method: 'post',
        body: payload,
    };
    return apiRequest(option).pipe(
        mergeMap(response => {
            if (response.order) {
                log({
                    tag: 'GET_ORDER',
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getOrder',
                    statusAction: 'SUCCESS',
                    triggerButton: 'email-continue-subscription',
                });
                return of(response);
            }
            const message = response.message || response.errorMessage;
            log({
                tag: 'GET_ORDER',
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getOrder',
                statusAction: 'FAILURE',
                triggerButton: 'email-continue-subscription',
            });
            return throwError({ message });
        }),
        catchError(error =>
            of(
                getOrderFailure({
                    error: error.message,
                })
            )
        )
    );
};

const preparePayloadGetPackages = ({ userType, contracts }) => {
    const estimates = [];
    // flat() does not work in some browsers
    contracts.forEach(contract => {
        contract.estimates.forEach(est => {
            estimates.push({
                ...est,
                energy: contract.energy,
            });
        });
    });
    return {
        mode: 'ESTIMATE',
        customerType: userType,
        estimates,
    };
};

const mapGetPackages = (action, { apiRequest }) => {
    const payload = preparePayloadGetPackages(action.payload);
    return apiRequest({
        path: '/getPackages',
        method: 'post',
        body: payload,
    }).pipe(
        mergeMap(response => {
            if (response.packagesList) {
                log({
                    tag: 'GET_ORDER',
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getPackages',
                    statusAction: 'SUCCESS',
                    triggerButton: 'email-continue-subscription',
                });
                return of(response);
            }
            const message = response.message || response.errorMessage;

            log({
                tag: 'GET_ORDER',
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getPackages',
                statusAction: 'FAILURE',
                triggerButton: 'email-continue-subscription',
            });
            return throwError({ message });
        }),
        catchError(error =>
            of(
                getPackageFailure({
                    error: error.message,
                })
            )
        )
    );
};

const preparePayloadGetProduct = ({ userType, contracts }) => {
    const modifiedContracts = contracts.map(contract => {
        const {
            effectiveStartRange,
            effectiveStartDate,
            subscriptionDate,
            ...c
        } = contract;
        return c;
    });
    return {
        contextOfUse: 'MARKET',
        customerType: userType,
        contracts: modifiedContracts,
    };
};

const preparePayloadGetOffer = ({ contracts, userType }) => {
    const updateArr = contracts.map(contract =>
        contract.chosenPackages === undefined
            ? { ...contract, chosenPackages: [] }
            : contract
    );

    return {
        contracts: updateArr,
        customerType: userType,
    };
};

const mapGetProducts = (action, { apiRequest }) => {
    const payload = preparePayloadGetProduct(action.payload);
    return apiRequest({
        path: '/getProducts',
        method: 'post',
        body: payload,
    }).pipe(
        switchMap(response => {
            if (response.productsList) {
                log({
                    tag: 'GET_ORDER',
                    returnCode: '200',
                    logMessage: null,
                    serviceCalled: '/getProducts',
                    statusAction: 'SUCCESS',
                    triggerButton: 'email-continue-subscription',
                });
                return of({
                    products: response.productsList,
                    error: null,
                });
            }

            log({
                tag: 'GET_ORDER',
                returnCode: response.code,
                logMessage: response.message,
                serviceCalled: '/getProducts',
                statusAction: 'FAILURE',
                triggerButton: 'email-continue-subscription',
            });
            const message = response.message || response.errorMessage;
            return throwError({ message });
        }),
        switchMap(({ products }) => {
            const payloadOffer = preparePayloadGetOffer(action.payload);
            return apiRequest({
                path: '/getOffers',
                method: 'post',
                body: payloadOffer,
            }).pipe(
                switchMap(response => {
                    if (response.offers) {
                        log({
                            tag: 'GET_ORDER',
                            returnCode: '200',
                            logMessage: null,
                            serviceCalled: '/getOffers',
                            statusAction: 'SUCCESS',
                            triggerButton: 'email-continue-subscription',
                        });
                        return of({
                            products,
                            offers: response.offers,
                        });
                    }
                    const message = response.message || response.errorMessage;
                    log({
                        tag: 'GET_ORDER',
                        returnCode: response.code,
                        logMessage: response.message,
                        serviceCalled: '/getOffers',
                        statusAction: 'FAILURE',
                        triggerButton: 'email-continue-subscription',
                    });
                    return throwError({ message });
                }),
                catchError(error =>
                    of(
                        getOfferFailure({
                            error: error.message,
                        })
                    )
                )
            );
        }),
        catchError(error =>
            of(
                getProductFailure({
                    error: error.message,
                })
            )
        )
    );
};

const getOrderEpic = (action$, state$, dependency) =>
    action$.pipe(
        ofType(MAIN_SCREEN_GET_ORDER),
        switchMap(
            action => mapGetOrder(action, dependency),
            (action, r) => [r]
        ),

        switchMap(([orderReponse]) => {
            const { order, channel } = orderReponse;
            const url =
                window.location !== window.parent.location
                    ? document.referrer
                    : document.location.href;
            const params = new URL(url).searchParams;
            const status = params.get('status');
            const diff = dateDiffDays(order.contracts[0].subscriptionDate);

            // Expired link if subscriptionDate > 7 days or status is 'success'
            const statuss =
                status !== 'success' &&
                (order.orderStatus === 'FINALIZED' ||
                    order.orderStatus === 'FINALIZED_WITHOUT_DOC') &&
                (order.firstPaymentCBStatus === 'FINALIZED' || 
                    order.firstPaymentSlimCollectStatus === 'FINALIZED');

            if (diff > 7 || !statuss) {
                const getAction = {
                    payload: {
                        userType: order.customer.type,
                        contracts: order.contracts,
                    },
                };

                if (channel) {
                    localStorage.setItem('channel', channel);
                }
                return forkJoin(
                    of(order),
                    mapGetPackages(getAction, dependency),
                    mapGetProducts(getAction, dependency)
                ).pipe(
                    catchError(error =>
                        // Handle the error here or rethrow it if needed
                        throwError({
                            code: 2,
                            message: 'Error get order',
                        })
                    )
                );
            }
            // Emit an error observable
            return throwError({
                code: 1,
                message: 'Error get order',
            });
        }),

        switchMap(([order, packageResponse, productResponse]) => {
            if (packageResponse.error && productResponse.error) {
                return of(
                    getOrderFailure({
                        error: 'Serveur répond avec erreur',
                    })
                );
            }
            const payload = {
                order,
                products: productResponse.products || [],
                offers: productResponse.offers || [],
                packages: packageResponse.packagesList,
                prepaymentFrequencies: packageResponse.prepaymentFrequencies,
                autorizedBillingModes: packageResponse.autorizedBillingModes,
            };

            const moveInCheck = order.contracts.find(
                ({ deliveryPoint, billingModeCode }) =>
                    deliveryPoint.process.type === 'MOVE_IN' &&
                    billingModeCode !== 'CYCLICAL_BILLING'
            );
            if (moveInCheck !== undefined) {
                const energyTypes = order.contracts.reduce(
                    (acc, curr) =>
                        curr.billingModeCode !== 'CYCLICAL_BILLING'
                            ? [...acc, curr.energy]
                            : [...acc],
                    []
                );

                return forkJoin(
                    of(payload),
                    ...energyTypes.map(energy => {
                        const currContract = order.contracts.find(
                            el => el.energy === energy
                        );
                        let fromDate = '';
                        if (currContract && currContract.effectiveStartDate) {
                            fromDate = moment(currContract.effectiveStartDate)
                                .subtract(1, 'days')
                                .format('YYYY-MM-DD');
                        }

                        const actionCalendar = {
                            payload: {
                                fromDate,
                                userType: order.customer.type,
                                offers:  productResponse.offers || [],
                                contracts: [
                                    {
                                        deliveryPoint: {
                                            state:
                                                currContract.deliveryPoint
                                                    .state || '',
                                            meterType:
                                                currContract.deliveryPoint
                                                    .meterType || '',
                                            smartMeterStatus:
                                                currContract.deliveryPoint
                                                    .smartMeterStatus || '',
                                            deliveryStatus:
                                                currContract.deliveryPoint
                                                    .deliveryStatus || '',
                                        },
                                    },
                                ],
                            },
                        };

                        return mapGetCalendarAndAppointment(
                            actionCalendar,
                            energy,
                            dependency
                        );
                    })
                );
            }

            return forkJoin(of(payload));
        }),

        switchMap(([payload, calendarResponse, calendarResponseBiEnergy]) => {
            const { order } = payload;

            if (calendarResponse !== undefined) {
                let arr = [...calendarResponse];

                if (calendarResponseBiEnergy !== undefined)
                    arr = [...arr, ...calendarResponseBiEnergy];
                const arrFlat = [].concat(arr);

                let isPaymentCB = false;

                let newContracts = order.contracts.reduce((acc, current) => {
                    if (current.billingModeCode !== 'CYCLICAL_BILLING') {
                        const findAppointment = arrFlat.find(
                            el => el.energy === current.energy
                        );

                        const findDate = findAppointment.daysList.find(
                            date => date.date === current.effectiveStartDate
                        );
                        if (findDate !== undefined && findDate.firstPaymentCB) {
                            isPaymentCB = true;
                            return [
                                ...acc,
                                { ...current, firstPaymentCB: true },
                            ];
                        }
                        acc.push({
                            ...removeFirstPaymentCBFromContract(current),
                        });

                        return acc;
                    }
                    acc.push({
                        ...removeFirstPaymentCBFromContract(current),
                    });

                    return acc;
                }, []);

                if (newContracts.length === 2 && isPaymentCB) {
                    newContracts = newContracts.map(contract =>
                        contract.billingModeCode !== 'CYCLICAL_BILLING'
                            ? {
                                  ...contract,
                                  firstPaymentCB: true,
                              }
                            : { ...contract }
                    );
                }

                const newPayload = {
                    ...payload,
                    order: {
                        ...order,
                        contracts: newContracts,
                    },
                };

                return of(getOrderSuccess(newPayload));
            }
            return of(getOrderSuccess(payload));
        }),
        catchError(error =>
            of(
                getOrderFailure({
                    error: error.message,
                    code: error.code,
                })
            )
        )
    );

export const mainScreenEpic = combineEpics(saveOrderEpic, getOrderEpic);
